import { Checkbox, Form, Input, Radio, Select } from "antd";
import styles from "./CustomInput.module.css";

function CustomInput({ label, name }) {
  return (
    <div className={styles.input__container}>
      <h4 className={styles.input__title}>{label}</h4>
      <Form.Item
        name={name}
        rules={[
          {
            required: true,
            message: "use only lower and uppercase letters",
          },
        ]}
      >
        <Input placeholder={`Enter your ${label}`} size="large" />
      </Form.Item>
    </div>
  );
}

export default CustomInput;
