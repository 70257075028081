const PhotocamIcon = ({ currentColor }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.2869 7.72158H17.8732L15.4907 3.41362C15.4276 3.29931 15.3073 3.22852 15.1769 3.22852H8.73256C8.60102 3.22852 8.48025 3.3005 8.41735 3.41602L6.07695 7.72158H2.62925C2.43098 7.72158 2.27051 7.88205 2.27051 8.08032V20.3283C2.27051 20.5263 2.43098 20.6871 2.62925 20.6871H21.2869C21.485 20.6871 21.6457 20.5263 21.6457 20.3283V8.08032C21.6457 7.88205 21.485 7.72158 21.2869 7.72158ZM20.9282 19.9696H2.98798V8.43905H6.29028C6.42182 8.43905 6.54259 8.36707 6.60549 8.25155L8.94589 3.94599H14.9653L17.3478 8.25395C17.4109 8.36826 17.5312 8.43905 17.6615 8.43905H20.928V19.9696H20.9282Z"
        fill={currentColor}
        stroke={currentColor}
        strokeWidth="0.5"
      />
      <path
        d="M11.9599 7.12891C8.76668 7.12891 6.16895 9.7264 6.16895 12.9194C6.16895 16.1119 8.76668 18.7089 11.9599 18.7089C15.1503 18.7089 17.7456 16.1119 17.7456 12.9194C17.7456 9.7264 15.1503 7.12891 11.9599 7.12891ZM11.9599 17.9915C9.16249 17.9915 6.88642 15.7161 6.88642 12.9194C6.88642 10.1222 9.16249 7.84638 11.9599 7.84638C14.7547 7.84638 17.0282 10.1222 17.0282 12.9194C17.0282 15.7161 14.7545 17.9915 11.9599 17.9915Z"
        fill={currentColor}
        stroke={currentColor}
        strokeWidth="0.5"
      />
      <path
        d="M11.9579 9.30371C9.96451 9.30371 8.34277 10.9259 8.34277 12.9198C8.34277 14.9132 9.96451 16.5349 11.9579 16.5349C13.9494 16.5349 15.5697 14.9132 15.5697 12.9198C15.5697 10.9259 13.9494 9.30371 11.9579 9.30371ZM11.9579 15.8174C10.3601 15.8174 9.06025 14.5174 9.06025 12.9198C9.06025 11.3215 10.3603 10.0212 11.9579 10.0212C13.5538 10.0212 14.8522 11.3215 14.8522 12.9198C14.8522 14.5174 13.5538 15.8174 11.9579 15.8174Z"
        fill={currentColor}
        stroke={currentColor}
        strokeWidth="0.5"
      />
    </svg>
  );
};

export default PhotocamIcon;
