import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import styles from "./search-results.module.css";
import TopMenu from "../../components/top-menu/top-menu";
import OrderCard from "../../components/order-card/order-card";
import { loadOrders } from "../../services/orders/actions";
import { selectOrders } from "../../services/orders/reducer";
import { deleteOrderValue } from "../../services/order/reducer";

function SearchResults() {
  const dispatch = useDispatch();

  const ordersToShow = useSelector(selectOrders);

  useEffect(() => {
    dispatch(deleteOrderValue());
    dispatch(loadOrders());
  }, [dispatch]);

  return (
    <div className={styles.search_results}>
      <TopMenu />
      <div className={styles.search_results__container}>
        {ordersToShow
          ?.slice()
          .reverse()
          .map((item) => (
            <OrderCard item={item} key={uuidv4()} />
          ))}
      </div>
    </div>
  );
}

export default SearchResults;
