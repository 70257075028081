import DetailedOrder from '../../components/DetailedOrder/DetailedOrder';

function DetailedOrderPage() {
  return (
    <section>
      <DetailedOrder />
    </section>
  );
}

export default DetailedOrderPage;
