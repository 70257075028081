const LikeIcon = ({ currentColor }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.8423 3C14.8254 3 13.091 4.23392 12.355 5.98664C11.6186 4.23392 9.88344 3 7.86554 3C5.18279 3 3 5.18204 3 7.86428C3 8.89937 3.33431 9.89726 3.97527 10.7612L12.0607 20.8587C12.1323 20.948 12.2404 21 12.355 21C12.4697 21 12.5782 20.9477 12.6496 20.8582L20.91 10.5063C20.9178 10.4963 20.9256 10.4857 20.9326 10.4749C21.4411 9.6826 21.7101 8.77973 21.7101 7.86428C21.7103 5.18204 19.5263 3 16.8423 3ZM20.3085 10.0516L12.3545 20.0195L4.57224 10.3009C4.03685 9.57879 3.75382 8.73624 3.75382 7.86428C3.75382 5.59779 5.59829 3.75407 7.86529 3.75407C10.133 3.75407 11.978 5.59779 11.978 7.86428C11.978 8.07266 12.1467 8.24132 12.355 8.24132C12.5634 8.24132 12.7321 8.07266 12.7321 7.86428C12.7321 5.59779 14.5758 3.75407 16.8423 3.75407C19.1105 3.75407 20.9563 5.59779 20.9563 7.86428C20.9563 8.62916 20.7323 9.385 20.3085 10.0516Z"
        fill={currentColor}
        stroke={currentColor}
        strokeWidth="0.5"
      />
    </svg>
  );
};

export default LikeIcon;
