import { createSlice } from "@reduxjs/toolkit";
import { offerPrice, takeOrder } from "./actions";

const initialState = {
  takeOrder: null,
  error: null,
  loading: false,

  isPriceOfferOpen: false,
  note: null,
  attempts: null,
};

export const takeOrderSlice = createSlice({
  name: "takeOrder",
  initialState,
  reducers: {
    setIsPriceOfferOpen: (state, action) => {
      state.isPriceOfferOpen = action.payload;
    },
    setTakeOrderError: (state, action) => {
      state.error = action.payload;
    },
    setTakeOrderNote: (state, action) => {
      state.note = action.payload;
    },
    setTakeOrderAttempts: (state, action) => {
      state.note = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(takeOrder.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(takeOrder.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(takeOrder.fulfilled, (state, action) => {
        state.loading = false;
        state.takeOrder = action.payload;
        state.note = action.payload.message;
      })

      .addCase(offerPrice.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(offerPrice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(offerPrice.fulfilled, (state, action) => {
        state.loading = false;
        state.takeOrder = action.payload;
        state.note = action.payload.message;
        state.attempts = action.payload.attempts;
      });
  },
});

export const {
  setIsPriceOfferOpen,
  setTakeOrderError,
  setTakeOrderNote,
  setTakeOrderAttempts,
} = takeOrderSlice.actions;

export const selectTakeOrder = (state) => state.takeOrder.takeOrder;
export const selectTakeOrderLoading = (state) => state.takeOrder.loading;
export const selectTakeOrderError = (state) => state.takeOrder.error;

export const isPriceOfferOpen = (state) => state.takeOrder.isPriceOfferOpen;
export const selectTakeOrderNote = (state) => state.takeOrder.note;
export const selectTakeOrderAttempts = (state) => state.takeOrder.attempts;

export const reducer = takeOrderSlice.reducer;
export default reducer;
