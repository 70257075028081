const MessageIcon = ({ currentColor }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="9"
        cy="10.5"
        r="0.375"
        fill={currentColor}
        stroke={currentColor}
        strokeWidth="0.7"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="12"
        cy="10.5"
        r="0.375"
        fill={currentColor}
        stroke={currentColor}
        strokeWidth="0.7"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="15"
        cy="10.5"
        r="0.375"
        fill={currentColor}
        stroke={currentColor}
        strokeWidth="0.7"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.25 3C4.95 3 2.25 5.7 2.25 9V18V21.75C5.025 19.35 8.55 18 12.225 18H15.75C19.05 18 21.75 15.3 21.75 12V9C21.75 5.7 19.05 3 15.75 3H8.25Z"
        stroke={currentColor}
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MessageIcon;
