const ArrowForwardIcon = ({ currentColor }) => {
  return (
    <svg
      width="75"
      height="20"
      viewBox="0 0 75 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M70.8536 10.3536C71.0488 10.1583 71.0488 9.84171 70.8536 9.64645L67.6716 6.46447C67.4763 6.2692 67.1597 6.2692 66.9645 6.46447C66.7692 6.65973 66.7692 6.97631 66.9645 7.17157L69.7929 10L66.9645 12.8284C66.7692 13.0237 66.7692 13.3403 66.9645 13.5355C67.1597 13.7308 67.4763 13.7308 67.6716 13.5355L70.8536 10.3536ZM5.5 9.5C5.22385 9.5 5 9.72386 5 10C5 10.2761 5.22385 10.5 5.5 10.5V9.5ZM70.5 9.5L5.5 9.5V10.5L70.5 10.5V9.5Z"
        fill={currentColor}
      />
    </svg>
  );
};

export default ArrowForwardIcon;
