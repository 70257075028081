import styles from './edit-order.module.css';

function EditOrder() {
  return (
    <div className={styles.delete_account}>
      <div className={styles.delete_account__container}>
        <label>edit-order</label>
      </div>
    </div>
  );
}

export default EditOrder;
