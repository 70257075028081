import styles from "./preloader-blank.module.css";

function PreloaderBlank() {
  return (
    <div className={styles.preloader_blank__container}>
      <div className={styles.preloader_blank}></div>;
    </div>
  );
}

export default PreloaderBlank;
