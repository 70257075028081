import { createSlice } from "@reduxjs/toolkit";
import {
  getUser,
  registerUser,
  loginUser,
  logoutUser,
  receiveUserOrders,
} from "./actions";

const initialState = {
  user: null,
  error: null,
  loading: false,

  isUserAuthChecked: false,

  orders: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setIsUserAuthChecked: (state, action) => {
      state.isUserAuthChecked = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload.user;
    },
    setUserError: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.user;
      })

      .addCase(registerUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.user;
      })

      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.user;
      })

      .addCase(logoutUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(logoutUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = null;
      })

      .addCase(receiveUserOrders.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(receiveUserOrders.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(receiveUserOrders.fulfilled, (state, action) => {
        state.loading = false;
        state.orders = action.payload.orders;
      });
  },
});

export const { setIsUserAuthChecked, setUser, setUserError } =
  userSlice.actions;

export const selectUser = (state) => state.user.user;
export const selectUserLoading = (state) => state.user.loading;
export const selectUserError = (state) => state.user.error;
export const selectUserOrders = (state) => state.user.orders;

export const checkUserAuth = (state) => state.user.isUserAuthChecked;
export const checkUserAuthLoading = (state) => state.user.loading;
export const checkUserAuthError = (state) => state.user.error;

export const reducer = userSlice.reducer;
export default reducer;
