import { createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../../utils/api";

export const takeOrder = createAsyncThunk("order/takeOrder", async (id) => {
  return api.takeNewOrder(id);
});

export const offerPrice = createAsyncThunk(
  "order/offerPrice",
  async ({ e, id }) => {
    return api.offerNewPrice(e, id);
  }
);
