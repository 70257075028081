import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ConfigProvider } from 'antd';

import styles from './app.module.css';

import CompletedOrders from '../../pages/completed-orders/completed-orders';
import CurrentOrders from '../../pages/current-orders/current-orders';
import DeleteAccount from '../../pages/delete-account/delete-account';
import DetailedOrder from '../../pages/detailed-order/detailed-order';
import DetailedPhoto from '../detailed-photo/detailed-photo';
import Error from '../error/error';
import Footer from '../footer/footer';
import FavoriteOrders from '../../pages/favorite-orders/favorite-orders';
import ForgotPassword from '../../pages/forgot-password/forgot-password';
import Header from '../header/header';
import Login from '../../pages/login/login';
import Messages from '../../pages/messages/messages';
import Modal from '../ui/modal/modal';
import NotFound404 from '../../pages/not-found-404/not-found-404';
import OrderCreate from '../../pages/order-create/order-create';
import PaymentDetails from '../../pages/payment-details/payment-details';
import Profile from '../../pages/profile/profile';
import PreloaderBlank from '../ui/preloader-blank/preloader-blank';
import PreloaderCar from '../ui/preloader-car/preloader-car';
import Support from '../../pages/support/support';
import Start from '../../pages/start/start';
import Settings from '../../pages/settings/settings';
import Signup from '../../pages/signup/signup';
import SearchOrders from '../../pages/search-orders/search-orders';
import SearchResults from '../../pages/search-results/search-results';
import SearchDetailedResults from '../../pages/search-detailed-results/search-detailed-results';
import TermsAndConditions from '../../pages/terms-and-conditions/terms-and-conditions';

import { OnlyAuth, OnlyUnAuth } from '../protected-route/protected-route';
import {
  selectOrderError,
  selectOrderLoading,
  setOrderError,
} from '../../services/order/reducer';

import {
  selectOrdersError,
  selectOrdersLoading,
  setOrdersError,
} from '../../services/orders/reducer';
import { getUser } from '../../services/user/actions';

import {
  selectUserError,
  selectUserLoading,
  setIsUserAuthChecked,
  setUser,
  setUserError,
} from '../../services/user/reducer';

import {
  selectCreateOrderError,
  selectCreateOrderNote,
  setCreateOrderError,
  setCreateOrderNote,
} from '../../services/create-order/reducer';

import {
  selectSearchedOrdersError,
  setSearchedOrdersError,
} from '../../services/searched-orders/reducer';

import {
  SEARCH_COMMON,
  COMPLETED_ORDERS,
  CURRENT_ORDERS,
  DELETE_ACCOUNT,
  FAVORITE_ORDERS,
  FORGOT_PASSWORD,
  LOGIN,
  MESSAGES,
  NUMBER,
  ORDER_CREATE,
  ORDER_PHOTO,
  ORDERS,
  PAYMENT_DETAILS,
  PROFILE,
  SEARCH_ORDERS,
  SEARCH_RESULTS,
  SETTINGS,
  SIGNUP,
  START,
  SUPPORT,
  TERMS_CONDITIONS,
  EDIT_ORDER,
} from '../../utils/constants';
import {
  isPriceOfferOpen,
  selectTakeOrder,
  selectTakeOrderError,
  selectTakeOrderNote,
  setIsPriceOfferOpen,
  setTakeOrderError,
} from '../../services/take-order/reducer';
import PriceOffer from '../PriceOffer/PriceOffer';
import Note from '../Note/Note';
import UI from '../../pages/UI/UI';
import EditOrder from '../../pages/edit-order/edit-order';

function App() {
  const location = useLocation();
  const dispatch = useDispatch();

  const isOrderLoading = useSelector(selectOrderLoading);
  const isOrdersLoading = useSelector(selectOrdersLoading);
  const isOrderLoad = isOrderLoading || isOrdersLoading;

  const isUserLoad = useSelector(selectUserLoading);

  const isCreateOrderError = useSelector(selectCreateOrderError);
  const isOrderError = useSelector(selectOrderError);
  const isOrdersError = useSelector(selectOrdersError);
  const isSearchedOrdersError = useSelector(selectSearchedOrdersError);
  const isTakeOrderError = useSelector(selectTakeOrderError);
  const isUserError = useSelector(selectUserError);

  const isError =
    isCreateOrderError ||
    isOrderError ||
    isOrdersError ||
    isSearchedOrdersError ||
    isTakeOrderError ||
    isUserError;

  const isCreateOrderNote = useSelector(selectCreateOrderNote);
  const isTakeOrderNote = useSelector(selectTakeOrderNote);

  const isNote = isCreateOrderNote || isTakeOrderNote;

  const isModalPriceOfferOpen = useSelector(isPriceOfferOpen);

  const currentUrl = location.pathname.split('/').pop();

  const isUnAuthPage =
    currentUrl !== 'login' &&
    currentUrl !== 'signup' &&
    currentUrl !== 'forgot-password';

  useEffect(() => {
    if (localStorage.getItem('accessToken')) {
      dispatch(getUser())
        .catch(() => {
          localStorage.removeItem('accessToken');
          localStorage.removeItem('refreshToken');
          dispatch(setUser(null));
        })
        .finally(() => dispatch(setIsUserAuthChecked(true)));
    } else {
      dispatch(setIsUserAuthChecked(true));
    }
  }, []);

  function handleOnErrorClose() {
    dispatch(setCreateOrderError(null));
    dispatch(setOrderError(null));
    dispatch(setOrdersError(null));
    dispatch(setSearchedOrdersError(null));
    dispatch(setTakeOrderError(null));
    dispatch(setUserError(null));
  }

  function handleOnNoteClose() {
    dispatch(setCreateOrderNote(null));
  }

  function handleOnPriceOfferClose() {
    dispatch(setIsPriceOfferOpen(false));
  }

  return (
    <div className={styles.app}>
      {isOrderLoad && <PreloaderCar />}
      {isUserLoad && <PreloaderBlank />}

      {isError && (
        <Modal handleOnClose={handleOnErrorClose}>
          <Error errorText={isError} />
        </Modal>
      )}

      {isNote && (
        // <Modal handleOnClose={handleOnNoteClose} isLoading={true}>
        <Modal isLoading={true}>
          <Note noteText={isNote} />
        </Modal>
      )}

      {isModalPriceOfferOpen && (
        <Modal handleOnClose={handleOnPriceOfferClose} isLoading={true}>
          <PriceOffer />
        </Modal>
      )}

      <ConfigProvider
        theme={{
          token: {
            borderRadius: 5,
            colorBorder: 'var(--dark-grey)',
            controlHeightLG: 40,
          },
          components: {
            DatePicker: {
              cellWidth: 32,
            },
          },
        }}
      >
        <Header />

        <div className={styles.app__main}>
          <Routes>
            <Route
              path={PROFILE}
              element={<OnlyAuth component={<Profile />} />}
            />
            {/* <Route
            path={SEARCH_COMMON}
            element={<OnlyUnAuth component={<SearchResults />} />}
          /> */}
            <Route path={SEARCH_COMMON} element={<SearchResults />} />
            {/* маршрут не требуется, но будет пока использоваться при нажатии на лого */}
            <Route
              path={SEARCH_RESULTS}
              element={<OnlyAuth component={<SearchDetailedResults />} />}
            />
            <Route
              path={`${ORDERS}${NUMBER}`}
              element={<OnlyAuth component={<DetailedOrder />} />}
            />
            <Route
              path={`${ORDERS}${NUMBER}${EDIT_ORDER}`}
              element={<OnlyAuth component={<EditOrder />} />}
            />
            <Route
              path={SEARCH_ORDERS}
              element={<OnlyAuth component={<SearchOrders />} />}
            />
            <Route
              path={FAVORITE_ORDERS}
              element={<OnlyAuth component={<FavoriteOrders />} />}
            />
            <Route
              path={CURRENT_ORDERS}
              element={<OnlyAuth component={<CurrentOrders />} />}
            />
            <Route
              path={COMPLETED_ORDERS}
              element={<OnlyAuth component={<CompletedOrders />} />}
            />
            <Route
              path={ORDER_CREATE}
              element={<OnlyAuth component={<OrderCreate />} />}
            />
            <Route
              path={PAYMENT_DETAILS}
              element={<OnlyAuth component={<PaymentDetails />} />}
            />
            <Route
              path={SETTINGS}
              element={<OnlyAuth component={<Settings />} />}
            />
            <Route
              path={TERMS_CONDITIONS}
              element={<OnlyAuth component={<TermsAndConditions />} />}
            />
            <Route
              path={MESSAGES}
              element={<OnlyAuth component={<Messages />} />}
            />
            <Route
              path={DELETE_ACCOUNT}
              element={<OnlyAuth component={<DeleteAccount />} />}
            />
            <Route
              path={SUPPORT}
              element={<OnlyAuth component={<Support />} />}
            />
            <Route
              path={`${ORDERS}${NUMBER}/${ORDER_PHOTO}${NUMBER}`}
              element={<DetailedPhoto />}
            />
            <Route
              path={LOGIN}
              element={<OnlyUnAuth component={<Login />} />}
            />
            <Route
              path={START}
              element={<OnlyUnAuth component={<Start />} />}
            />
            <Route
              path={SIGNUP}
              element={<OnlyUnAuth component={<Signup />} />}
            />
            <Route
              path={FORGOT_PASSWORD}
              element={<OnlyUnAuth component={<ForgotPassword />} />}
            />
            <Route path="ui" element={<UI />} />
            <Route path="*" element={<NotFound404 />} />
          </Routes>
        </div>
        {isUnAuthPage && <Footer />}
      </ConfigProvider>
    </div>
  );
}

export default App;
