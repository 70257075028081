import { createSlice } from "@reduxjs/toolkit";
import { createOrder } from "./actions";

const initialState = {
  createOrder: null,
  error: null,
  loading: false,

  note: null,
};

export const createOrderSlice = createSlice({
  name: "createOrder",
  initialState,
  reducers: {
    setCreateOrderError: (state, action) => {
      state.error = action.payload;
    },
    setCreateOrderNote: (state, action) => {
      state.note = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createOrder.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createOrder.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createOrder.fulfilled, (state, action) => {
        state.loading = false;
        state.createOrder = action.payload;
      });
  },
});

export const { setCreateOrderError, setCreateOrderNote } =
  createOrderSlice.actions;

export const selectCreateOrder = (state) => state.createOrder.createOrder;
export const selectCreateOrderLoading = (state) => state.createOrder.loading;
export const selectCreateOrderError = (state) => state.createOrder.error;

export const selectCreateOrderNote = (state) => state.createOrder.note;

export const reducer = createOrderSlice.reducer;
export default reducer;
