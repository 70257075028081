import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styles from './DetailedOrder.module.css';
import StarRating from '../../components/star-rating/star-rating';
import { selectOrder } from '../../services/order/reducer';
import { FROM, SENDER, TO } from '../../utils/constants';

import CommonButton from '../../components/ui/common-button/common-button';
import { setIsPriceOfferOpen } from '../../services/take-order/reducer';
import { takeOrder } from '../../services/take-order/actions';
import ArrowBackIcon from '../../icons/ArrowBackIcon';
import LikeIcon from '../../icons/LikeIcon';
import { selectUser } from '../../services/user/reducer';
import MessageIcon from '../../icons/MessageIcon';
import Messages from '../../pages/messages/messages';
import EditIcon from '../../icons/EditIcon';

function DetailedOrder() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [likeStatus, setLikeStatus] = useState(false);

  const orderToShow = useSelector(selectOrder);
  const userToShow = useSelector(selectUser);
  const userRole = userToShow?.role;
  console.log(userRole);

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleLikeClick = () => {
    setLikeStatus(!likeStatus);
  };

  const handleOnOfferPriceClick = () => {
    dispatch(setIsPriceOfferOpen(true));
  };

  const handleOnTakeOrderClick = () => {
    dispatch(takeOrder(orderToShow.id));
  };

  const handleOnMessageClick = () => {
    navigate('/messages');
  };

  const handleEditClick = () => {
    navigate('./edit-order');
  };

  return (
    <div>
      {orderToShow ? (
        <div className={styles.detailed_order}>
          <div className={styles.detailed_order__main_container}>
            <p
              className={styles.detailed_order__price}
            >{`${orderToShow.price} €`}</p>
            <button
              type="button"
              className={styles.detailed_order__button}
              onClick={handleBackClick}
            >
              <ArrowBackIcon currentColor={'var(--dark-grey)'} />
            </button>
            <div className={styles.detailed_order__icon_container}>
              {userRole === 'courier' ? (
                <button
                  type="button"
                  className={styles.detailed_order__button}
                  onClick={handleLikeClick}
                >
                  <LikeIcon
                    currentColor={
                      likeStatus ? 'var(--dark-grey)' : 'var(--light-blue)'
                    }
                  />
                </button>
              ) : (
                <button
                  type="button"
                  className={styles.detailed_order__button}
                  onClick={handleEditClick}
                >
                  <EditIcon currentColor={'var(--dark-grey)'} />
                </button>
              )}
            </div>
          </div>
          <div className={styles.detailed_order__adress_container}>
            <span className={styles.detailed_order__title}>{FROM}</span>
            <p className={styles.detailed_order__town}>
              {orderToShow.from_town}
            </p>
            <p className={styles.detailed_order__street}>
              {`${orderToShow.from_street} ${orderToShow.from_house}, ${orderToShow.from_zip}`}
            </p>
            <div className={styles.detailed_order__date_container}>
              <p className={styles.detailed_order__date}>
                {new Date(orderToShow.from_datetime).toLocaleDateString()}
              </p>
              <p className={styles.detailed_order__time}>
                {new Date(orderToShow.from_datetime).toLocaleTimeString([], {
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </p>
            </div>
          </div>
          <div className={styles.detailed_order__adress_container}>
            <span className={styles.detailed_order__title}>{TO}</span>
            <p className={styles.detailed_order__town}>{orderToShow.to_town}</p>
            <p className={styles.detailed_order__street}>
              {`${orderToShow.to_street} ${orderToShow.to_house}, ${orderToShow.to_zip}`}
            </p>
            <div className={styles.detailed_order__date_container}>
              <p className={styles.detailed_order__date}>
                {new Date(orderToShow.to_datetime).toLocaleDateString()}
              </p>
              <p className={styles.detailed_order__time}>
                {new Date(orderToShow.to_datetime).toLocaleTimeString([], {
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </p>
            </div>
          </div>
          <div className={styles.detailed_order__sender_container}>
            <div className={styles.detailed_order__sender_name_container}>
              <span className={styles.detailed_order__title}>{SENDER}</span>

              <p className={styles.detailed_order__sender}>
                {orderToShow.sender}
              </p>
            </div>
            <div className={styles.detailed_order__sender_rating}>
              <StarRating rating={orderToShow.sender_rating} />
              <p className={styles.detailed_order__rating}>
                {orderToShow.sender_rating}
              </p>
            </div>
          </div>
          <p className={styles.detailed_order__description}>
            {orderToShow.detailed_description}
          </p>
          <div className={styles.detailed_order__photos_container}>
            {orderToShow?.photos.map((item, index) => (
              <img
                className={styles.detailed_order__photo}
                src={item}
                alt={`order-photo ${index + 1}`}
                key={index}
              />
            ))}
          </div>
          {userRole === 'courier' ? (
            <div className={styles.detailed_order__buttons_container}>
              <CommonButton
                type={'green'}
                title={'Offer your price'}
                disabled={false}
                onClick={handleOnOfferPriceClick}
              />
              <CommonButton
                type={'blue'}
                title={'Take order'}
                disabled={false}
                onClick={handleOnTakeOrderClick}
              />
            </div>
          ) : (
            <CommonButton
              type={'blue'}
              title={'New delivery price offers'}
              disabled={false}
              onClick={handleOnMessageClick}
              icon={<MessageIcon currentColor={'var(--white)'} />}
            />
          )}
        </div>
      ) : (
        ''
      )}
    </div>
  );
}

export default DetailedOrder;
