import styles from './UI.module.css';

import ArrowThinIcon from '../../icons/arrow-thin-icon';
import CarIcon from '../../icons/CarIcon';
import CardIcon from '../../icons/CardIcon';
import CloseIcon from '../../icons/CloseIcon';
import CurrentOrderIcon from '../../icons/CurrentOrderIcon';
import HandshakeIcon from '../../icons/HandshakeIcon';
import MessagesIcon from '../../icons/MessagesIcon';
import BoxIcon from '../../icons/BoxIcon';
import PeopleIcon from '../../icons/PeopleIcon';
import RouteIcon from '../../icons/RouteIcon';

import arrow_back_icon from '../../images/arrow_back_icon.svg';
import arrow_icon from '../../images/arrow_icon.svg';
import arrow_menu from '../../images/arrow_menu.svg';
import car_icon_dark from '../../images/car_icon_dark.svg';
import car_icon from '../../images/car_icon.svg';
import card_icon from '../../images/card_icon.svg';
import current_icon_active from '../../images/current_icon_active.svg';
import current_icon from '../../images/current_icon.svg';

import delete_icon from '../../images/delete_icon.svg';
import delivered_orders_icon from '../../images/delivered_orders_icon.svg';
import email_icon from '../../images/email_icon.svg';
import footer_current_icon_active from '../../images/footer_current_icon_active.svg';
import footer_current_icon from '../../images/footer_current_icon.svg';
import footer_like_icon_active from '../../images/footer_like_icon_active.svg';
import footer_like_icon from '../../images/footer_like_icon.svg';
import footer_search_icon_active from '../../images/footer_search_icon_active.svg';
import footer_search_icon from '../../images/footer_search_icon.svg';
import footer_support_icon_active from '../../images/footer_support_icon_active.svg';
import footer_support_icon from '../../images/footer_support_icon.svg';
import like_icon_active from '../../images/like_icon_active.svg';
import like_icon from '../../images/like_icon.svg';
import like_icon2 from '../../images/like_icon2.svg';
import like_shifted_icon_active from '../../images/like_shifted_icon_active.svg';
import like_shifted_icon from '../../images/like_shifted_icon.svg';
import linkedin_icon from '../../images/linkedin_icon.svg';
import logout_icon from '../../images/logout_icon.svg';
import menu_icon from '../../images/menu_icon.svg';
import messages_icon_active from '../../images/messages_icon_active.svg';
import messages_icon from '../../images/messages_icon.svg';
import motoroller_icon_dark from '../../images/motoroller_icon_dark.svg';
import motoroller_icon from '../../images/motoroller_icon.svg';
import new_order_icon_active from '../../images/new_order_icon_active.svg';
import new_order_icon from '../../images/new_order_icon.svg';

import photocam_icon from '../../images/photocam_icon.svg';
import point_a_icon from '../../images/point_a_icon.svg';
import point_b_icon from '../../images/point_b_icon.svg';
import sd_logo from '../../images/sd_logo.svg';
import search_icon_active from '../../images/search_icon_active.svg';
import search_icon from '../../images/search_icon.svg';
import settings_icon from '../../images/settings_icon.svg';

import sort_arrow_icon from '../../images/sort_arrow_icon.svg';
import sort_icon from '../../images/sort_icon.svg';
import star_icon from '../../images/star_icon.svg';
import support_icon_active from '../../images/support_icon_active.svg';
import support_icon from '../../images/support_icon_active.svg';
import telegram_icon from '../../images/telegram_icon.svg';
import terms_icon from '../../images/terms_icon.svg';
import truck_icon_dark from '../../images/truck_icon_dark.svg';
import truck_icon from '../../images/truck_icon.svg';
import user_icon from '../../images/user_icon.svg';
import ArrowForwardIcon from '../../icons/ArrowForwardIcon';

function UI() {
  return (
    <div>
      <p>Icons</p>
      <div style={{ display: 'flex' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div className={styles.icon}>
            <ArrowThinIcon currentColor={'black'} />
          </div>
          <ArrowThinIcon currentColor={'black'} />
          <div className={styles.icon}>
            <CarIcon currentColor={'black'} />
          </div>
          <CarIcon currentColor={'black'} />
          <div className={styles.icon}>
            <CardIcon currentColor={'black'} />
          </div>
          <div className={styles.icon}>
            <CloseIcon currentColor={'black'} />
          </div>
          <div className={styles.icon}>
            <CurrentOrderIcon currentColor={'black'} />
          </div>
          <div className={styles.icon}>
            <HandshakeIcon currentColor={'black'} />
          </div>
          <div className={styles.icon}>
            <MessagesIcon currentColor={'black'} />
          </div>
          <div className={styles.icon}>
            <BoxIcon currentColor={'black'} />
          </div>
          <div className={styles.icon}>
            <PeopleIcon currentColor={'black'} />
          </div>
          <div className={styles.icon}>
            <RouteIcon currentColor={'black'} />
          </div>
          <div className={styles.icon}>
            <ArrowForwardIcon currentColor={'var(--light-green)'} />
          </div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div className={styles.icon_svg}>
            <img src={arrow_back_icon} />
          </div>
          <div className={styles.icon_svg}>
            <img src={arrow_icon} />
          </div>
          <div className={styles.icon_svg}>
            <img src={arrow_menu} />
          </div>
          <div className={styles.icon_svg}>
            <img src={car_icon_dark} />
          </div>
          <div className={styles.icon_svg}>
            <img src={car_icon} />
          </div>
          <div className={styles.icon_svg}>
            <img src={card_icon} />
          </div>
          <div className={styles.icon_svg}>
            <img src={current_icon_active} />
          </div>
          <div className={styles.icon_svg}>
            <img src={current_icon} />
          </div>
          <div className={styles.icon_svg}>
            <img src={delete_icon} />
          </div>
          <div className={styles.icon_svg}>
            <img src={delivered_orders_icon} />
          </div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div className={styles.icon_svg}>
            <img src={email_icon} />
          </div>
          <div className={styles.icon_svg}>
            <img src={footer_current_icon_active} />
          </div>
          <div className={styles.icon_svg}>
            <img src={footer_current_icon} />
          </div>
          <div className={styles.icon_svg}>
            <img src={footer_like_icon_active} />
          </div>
          <div className={styles.icon_svg}>
            <img src={footer_like_icon} />
          </div>
          <div className={styles.icon_svg}>
            <img src={footer_search_icon_active} />
          </div>
          <div className={styles.icon_svg}>
            <img src={footer_search_icon} />
          </div>
          <div className={styles.icon_svg}>
            <img src={footer_support_icon_active} />
          </div>
          <div className={styles.icon_svg}>
            <img src={footer_support_icon} />
          </div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div className={styles.icon_svg}>
            <img src={like_icon_active} />
          </div>
          <div className={styles.icon_svg}>
            <img src={like_icon} />
          </div>
          <div className={styles.icon_svg}>
            <img src={like_icon2} />
          </div>
          <div className={styles.icon_svg}>
            <img src={like_shifted_icon_active} />
          </div>
          <div className={styles.icon_svg}>
            <img src={like_shifted_icon} />
          </div>
          <div className={styles.icon_svg}>
            <img src={linkedin_icon} />
          </div>
          <div className={styles.icon_svg}>
            <img src={logout_icon} />
          </div>
          <div className={styles.icon_svg}>
            <img src={menu_icon} />
          </div>
          <div className={styles.icon_svg}>
            <img src={messages_icon_active} />
          </div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div className={styles.icon_svg}>
            <img src={messages_icon} />
          </div>
          <div className={styles.icon_svg}>
            <img src={motoroller_icon_dark} />
          </div>
          <div className={styles.icon_svg}>
            <img src={motoroller_icon} />
          </div>
          <div className={styles.icon_svg}>
            <img src={new_order_icon_active} />
          </div>
          <div className={styles.icon_svg}>
            <img src={new_order_icon} />
          </div>
          <div className={styles.icon_svg}>
            <img src={photocam_icon} />
          </div>
          <div className={styles.icon_svg}>
            <img src={point_a_icon} />
          </div>
          <div className={styles.icon_svg}>
            <img src={point_b_icon} />
          </div>

          <div className={styles.icon_svg}>
            <img src={search_icon_active} />
          </div>
          <div className={styles.icon_svg}>
            <img src={search_icon} />
          </div>
          <div className={styles.icon_svg}>
            <img src={settings_icon} />
          </div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div className={styles.icon_svg}>
            <img src={sd_logo} />
          </div>
          <div className={styles.icon_svg}>
            <img src={sort_arrow_icon} />
          </div>
          <div className={styles.icon_svg}>
            <img src={sort_icon} />
          </div>
          <div className={styles.icon_svg}>
            <img src={star_icon} />
          </div>
          <div className={styles.icon_svg}>
            <img src={support_icon_active} />
          </div>
          <div className={styles.icon_svg}>
            <img src={support_icon} />
          </div>
          <div className={styles.icon_svg}>
            <img src={telegram_icon} />
          </div>
          <div className={styles.icon_svg}>
            <img src={terms_icon} />
          </div>
          <div className={styles.icon_svg}>
            <img src={truck_icon_dark} />
          </div>
          <div className={styles.icon_svg}>
            <img src={truck_icon} />
          </div>
          <div className={styles.icon_svg}>
            <img src={user_icon} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default UI;
