import styles from "./preloader-car.module.css";

function PreloaderCar() {
  return (
    <div className={styles.preloader_car__container}>
      <div className={styles.preloader_car}></div>;
    </div>
  );
}

export default PreloaderCar;
