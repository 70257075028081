import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import styles from "./current-orders.module.css";
import TopMenu from "../../components/top-menu/top-menu";
import OrderCard from "../../components/order-card/order-card";
import { selectUser, selectUserOrders } from "../../services/user/reducer";
import { receiveUserOrders } from "../../services/user/actions";

function CurrentOrders() {
  const dispatch = useDispatch();

  const ordersToShow = useSelector(selectUserOrders);
  const user = useSelector(selectUser);
  const userRole = user.role;

  useEffect(() => {
    if (userRole === "courier") {
      dispatch(receiveUserOrders("courier"));
    } else if (userRole === "sender") {
      dispatch(receiveUserOrders("sender"));
    }
  }, [dispatch]);

  return (
    <div className={styles.current_orders}>
      <TopMenu />
      <div className={styles.current_orders__container}>
        {ordersToShow
          ?.slice()
          .reverse()
          .map((item) => (
            <OrderCard item={item} key={uuidv4()} />
          ))}
      </div>
    </div>
  );
}

export default CurrentOrders;
