import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styles from './order-card.module.css';
import { loadOrder } from '../../services/order/actions';
import { ORDERS } from '../../utils/constants';
import arrow_icon from '../../images/arrow_icon.svg';
import point_a_icon from '../../images/point_a_icon.svg';
import point_b_icon from '../../images/point_b_icon.svg';
import truck_icon from '../../images/truck_icon.svg';
import car_icon from '../../images/car_icon.svg';
import motoroller_icon from '../../images/motoroller_icon.svg';
import like_shifted_icon from '../../images/like_shifted_icon.svg';
import like_shifted_icon_active from '../../images/like_shifted_icon_active.svg';
import BoxIcon from '../../icons/BoxIcon';
import ArrowForwardIcon from '../../icons/ArrowForwardIcon';
import PhotocamIcon from '../../icons/PhotocamIcon';
import CardIcon from '../../icons/CardIcon';
import MessageIcon from '../../icons/MessageIcon';

function OrderCard({ item }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const activeCard = location.pathname === '/current-orders' ? true : false;

  const handleUserShow = () => {
    dispatch(loadOrder(item.id));
    navigate(`${ORDERS}/${item.id}`);
  };

  const [likeStatus, setLikeStatus] = useState(true);

  const handleLikeClick = (event) => {
    event.stopPropagation();
    setLikeStatus(!likeStatus);
  };

  return (
    <div className={styles.order_card}>
      <button
        type="button"
        onClick={handleUserShow}
        className={styles.order_card__button}
      >
        <div className={styles.order_card__main_container}>
          <p className={styles.order_card__price}>{`${item.price} €`}</p>

          {!activeCard && (
            <div className={styles.order_card__icons_container}>
              {item.order_type === 'truck' && (
                <img
                  className={styles.order_card__icon}
                  src={truck_icon}
                  alt="truck_icon"
                />
              )}

              {item.order_type === 'car' && (
                <>
                  <img
                    className={styles.order_card__icon}
                    src={truck_icon}
                    alt="truck_icon"
                  />
                  <img
                    className={styles.order_card__icon}
                    src={car_icon}
                    alt="car_icon"
                  />
                </>
              )}

              {item.order_type === 'motoroller' && (
                <>
                  <img
                    className={styles.order_card__icon}
                    src={truck_icon}
                    alt="truck_icon"
                  />
                  <img
                    className={styles.order_card__icon}
                    src={car_icon}
                    alt="car_icon"
                  />
                  <img
                    className={styles.order_card__icon}
                    src={motoroller_icon}
                    alt="motoroller_icon"
                  />
                </>
              )}
            </div>
          )}

          {activeCard && (
            <>
              <BoxIcon currentColor={'var(--green)'} />
              <ArrowForwardIcon currentColor={'var(--green)'} />
              <PhotocamIcon currentColor={'var(--dark-grey)'} />
              <ArrowForwardIcon currentColor={'var(--dark-grey)'} />
              <CardIcon currentColor={'var(--dark-grey)'} />
            </>
          )}

          <p className={styles.order_card__description}>
            {item.short_description}
          </p>
        </div>

        <div className={styles.order_card__routh_like_container}>
          <div className={styles.order_card__routh_container}>
            <div className={styles.order_card__town_date_icons_container}>
              <div className={styles.order_card__routh_icons_container}>
                <img
                  className={styles.order_card__routh_point_icon_a}
                  src={point_a_icon}
                  alt="point_a_icon"
                />

                <div className={styles.order_card__arrow_container}>
                  <div className={styles.order_card__routh_arrow_pole}></div>
                  <img
                    className={styles.order_card__routh_arrow_icon}
                    src={arrow_icon}
                    alt="arrow_icon"
                  />
                </div>
              </div>

              <div className={styles.order_card__from_town_date_container}>
                <p className={styles.order_card__town}>{item.from_town}</p>

                <div className={styles.order_card__date_container}>
                  <p className={styles.order_card__date}>
                    {new Date(item.from_datetime).toLocaleDateString()}
                  </p>
                  <p className={styles.order_card__time}>
                    {new Date(item.from_datetime).toLocaleTimeString([], {
                      hour: '2-digit',
                      minute: '2-digit',
                    })}
                  </p>
                </div>
              </div>
            </div>

            <div className={styles.order_card__town_date_icons_container}>
              <img
                className={styles.order_card__routh_point_icon_b}
                src={point_b_icon}
                alt="point_b_icon"
              />

              <div className={styles.order_card__to_town_date_container}>
                <p className={styles.order_card__town}>{item.to_town}</p>

                <div className={styles.order_card__date_container}>
                  <p className={styles.order_card__date}>
                    {new Date(item.to_datetime).toLocaleDateString()}
                  </p>
                  <p className={styles.order_card__time}>
                    {new Date(item.to_datetime).toLocaleTimeString([], {
                      hour: '2-digit',
                      minute: '2-digit',
                    })}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </button>
      {!activeCard && (
        <button
          type="button"
          className={styles.order_card__like_button}
          onClick={handleLikeClick}
        >
          <img
            className={styles.order_card__like_icon}
            src={likeStatus ? like_shifted_icon : like_shifted_icon_active}
            alt="like_shifted_icon"
          />
        </button>
      )}
      {activeCard && (
        <div className={styles.order_card__message}>
          <MessageIcon currentColor={'var(--light-blue)'} />
        </div>
      )}
    </div>
  );
}

export default OrderCard;
