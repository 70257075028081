const ArrowBackIcon = ({ currentColor }) => {
  return (
    <svg
      width="36"
      height="29"
      viewBox="0 0 36 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.46967 13.9697C6.17678 14.2626 6.17678 14.7374 6.46967 15.0303L11.2426 19.8033C11.5355 20.0962 12.0104 20.0962 12.3033 19.8033C12.5962 19.5104 12.5962 19.0355 12.3033 18.7426L8.06066 14.5L12.3033 10.2574C12.5962 9.96447 12.5962 9.48959 12.3033 9.1967C12.0104 8.90381 11.5355 8.90381 11.2426 9.1967L6.46967 13.9697ZM29 15.25C29.4142 15.25 29.75 14.9142 29.75 14.5C29.75 14.0858 29.4142 13.75 29 13.75V15.25ZM7 15.25H29V13.75H7V15.25Z"
        fill={currentColor}
      />
    </svg>
  );
};

export default ArrowBackIcon;
